import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Tweet from "../../components/Tweet";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I've been rewriting parts of `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/cifilter-io/id1457458557?mt=8"
      }}>{`CIFilter.io`}</a>{` in SwiftUI, and one issue I ran into recently was that SwiftUI previews didn't seem to work as advertised. When I opened the preview, it would trigger an app build, eventually show up, but there would always be a banner telling me that previewing had been paused, with the following message:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Automatic preview updating pauses when the the previewed file is edited in a way which causes the containing module to be rebuilt.`}</p>
    </blockquote>
    <p>{`Every time I clicked the "Resume" button, the app would build again, and the banner would come up again. Initially, I wrote it off as SwiftUI previews just not working - since each preview triggered an app build, I just defaulted to building the app each time I changed something. Eventually though, I realized that other people weren't having this issue.`}</p>
    <h2 {...{
      "id": "clues"
    }}>{`Clues`}</h2>
    <p>{`A clue to what might be happening came up from `}<a parentName="p" {...{
        "href": "https://twitter.com/paulcolton"
      }}>{`Paul Colton`}</a>{` on Twitter:`}</p>
    <Tweet tweetId="1279072572471050240" mdxType="Tweet" />
    <p>{`As it turned out, I actually `}<em parentName="p">{`did`}</em>{` have some `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/cifilter.io/blob/97734a91559267bcf9c2b5e85c4e6e00d04c1230/iOS/CIFilter.io.xcodeproj/project.pbxproj#L605"
      }}>{`build phases`}</a>{` running as part of the project's build - various normal ones like copying bundle resources, embedding Pods frameworks, etc, and also some custom ones to write data into `}<inlineCode parentName="p">{`Info.plist`}</inlineCode>{` and upload dSYMs to Sentry for crash reporting. It seemed weird to me that these scripts could cause preview updating to fail, but then I realized that one of the scripts was not like the others.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/cifilter-io/id1457458557?mt=8"
      }}>{`CIFilter.io app`}</a>{` has a build phase which looks at various data (like the current git sha and number of commits) and writes it to a json file called `}<inlineCode parentName="p">{`buildInformation.json`}</inlineCode>{`. This file is included in the Xcode project, and the app reads it at runtime to figure out what version, sha, etc it's running for analytics purposes.`}</p>
    <p>{`The important thing about this script was that `}<em parentName="p">{`running it modified a file that was part of the Xcode project`}</em>{`. Because this script ran every preview build, it would modify a file in the project every time, which caused Xcode to pause automatic previewing.`}</p>
    <h2 {...{
      "id": "the-fix"
    }}>{`The Fix`}</h2>
    <p>{`To prove it, I created a `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSwiftUIPreviewBuildPhaseIssue"
      }}>{`sample project`}</a>{` which is a pared down version of this situation. A build script writes the current date to a file every build, and that file is included in the project (though not used). The same thing happens - endless loop of "Automatic preview updating paused".`}</p>
    <p>{`One option would be moving away from using that file entirely, but I found out a better way to resolve the issue - Xcode has an option to only run build phase scripts "on install", which really means that they `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1279816240874188800?s=20"
      }}>{`only get run on Archive builds`}</a>{`. If you check this option in the sample project, the script doesn't get run on preview builds, the file doesn't get updated, and previews work magically again. 🙌`}</p>
    <CaptionedImage max={250} filename="preview-updating.png" caption="This green indicator is what you want to see" alt="Image of the green circle indicating that automatic previewing in Xcode is enabled" mdxType="CaptionedImage" />
    <p>{`Checking this option worked great for my use case, and completely resolved the issue. It seems this is pretty much the only way to get around this, though - I tried to look into whether you could detect whether a build was for previews only and not update the file, but I wasn't able to figure out if it's possible. If you know differently, please `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`reach out`}</a>{` 👋`}</p>
    <blockquote>
      <p parentName="blockquote">{`The sample code demonstrating the preview updating issue is available at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSwiftUIPreviewBuildPhaseIssue"
        }}>{`NGSwiftUIPreviewBuildPhaseIssue`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      